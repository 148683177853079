import React, { Component } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import ProfilePic from '../profile-pic';
import { NameUtils } from '../../lib';

import styles from '../../styles/comment.scss';

export default class Comment extends Component {
  render() {
    const comment = this.props.comment;
    const user = comment.get('user');

    return (
      <div className={classnames(styles.body, this.props.className)}>
        <div>
          <ProfilePic url={user.get('profilePicUrl')}
                      size="small" />
          <span className={styles.name}>{NameUtils.fullOrEmail(user)}</span>
          <div className={styles.date}>{comment.get('date').from(moment())}</div>
        </div>
        <div className={styles.message}>{comment.get('message')}</div>
      </div>
    );
  }
}
