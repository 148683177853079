/* globals Stripe */

import config from './config';
import logger from './logger';

if ( typeof Stripe === 'undefined' ) {
  logger.error('Stripe is not defined, cannot set publishable key');
} else {
  Stripe.setPublishableKey(config.stripePublishableKey);
}

export class StripeError extends Error {
  static Fields = {
    EXPIRATION: 'expiration',
    CVC: 'cvc',
    CARD: 'card'
  };

  constructor(message, field) {
    super(message, field);
    this.name = this.constructor.name;
    this.message = message;
    this.field = field;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor.name);
    } else {
      this.stack = Error.stack || '';
    }
  }

  expirationError() {
    return this.errorFor(StripeError.Fields.EXPIRATION);
  }

  cvcError() {
    return this.errorFor(StripeError.Fields.CVC);
  }

  cardError() {
    return this.errorFor(StripeError.Fields.CARD);
  }

  errorFor(field) {
    return this.field === field
      ? this.message
      : null;
  }
}

/*
 * @param cardInfo - object to pass to stripe.  Expected to have keys: number, cvc, exp_month, exp_year
 */
export function getCardToken(cardInfo) {
  return new Promise((resolve, reject) => {
    Stripe.card.createToken(cardInfo, gotStripeData);

    function gotStripeData(ignoredStatus, response) {
      const error = response.error;

      if ( error ) {
        if ( error.param === 'exp_month' || error.param === 'exp_year' ) {
          return reject(new StripeError(error.message, StripeError.Fields.EXPIRATION));
        } else if ( error.param === 'cvc' ) {
          return reject(new StripeError(error.message, StripeError.Fields.CVC));
        }

        return reject(new StripeError(error.message, StripeError.Fields.CARD));
      }

      resolve(response.id);
    }
  });
}
