import moment from 'moment';
import { Map } from 'immutable';
import { pushPath } from 'redux-simple-router';
import { ActionTypes } from '../constants';
import { Api, NamedRoutes, stripe } from '../lib';

const { RECEIVED_COMMUNITY, REQUEST_COMMUNITY, FAILED_COMMUNITY } = ActionTypes;
const { REQUEST_COMMUNITY_COMMENTS, RECEIVED_COMMUNITY_COMMENTS, FAILED_COMMUNITY_COMMENTS } = ActionTypes;
const { POST_COMMUNITY_COMMENT, SUCCESSFULLY_POSTED_COMMUNITY_COMMENT, FAILED_POST_COMMUNITY_COMMENT } = ActionTypes;
const { SHOW_LEARN_HOW_CAMPAIGNS_WORK, HIDE_LEARN_HOW_CAMPAIGNS_WORK } = ActionTypes;
const { SHOW_PLEDGE, HIDE_PLEDGE } = ActionTypes;
const { POST_PLEDGE, SUCCESSFULLY_POSTED_PLEDGE, FAILED_POST_PLEDGE } = ActionTypes;

function receivedCommunity(community) {
  return {
    type: RECEIVED_COMMUNITY,
    payload: {
      community
    }
  };
}

function requestComments(id) {
  return {
    type: REQUEST_COMMUNITY_COMMENTS,
    payload: {
      id
    }
  };
}

function receivedComments(communityId, comments) {
  return {
    type: RECEIVED_COMMUNITY_COMMENTS,
    payload: {
      communityId,
      comments
    }
  };
}

function failedComments(error) {
  return {
    type: FAILED_COMMUNITY_COMMENTS,
    payload: {
      error
    }
  };
}

function requestCommunity(id) {
  return {
    type: REQUEST_COMMUNITY,
    payload: {
      id
    }
  };
}

function failedCommunity(err) {
  return {
    type: FAILED_COMMUNITY,
    payload: {
      error: err
    }
  };
}

function postCommunityComment(communityId, comment) {
  return {
    type: POST_COMMUNITY_COMMENT,
    payload: {
      communityId,
      comment
    }
  };
}

function successfullyPostedCommunityComment(communityId, localCommentId, remoteCommentId) {
  return {
    type: SUCCESSFULLY_POSTED_COMMUNITY_COMMENT,
    payload: {
      communityId,
      localCommentId,
      remoteCommentId
    }
  };
}

function failedPostCommunityComment(communityId, localCommentId, err) {
  return {
    type: FAILED_POST_COMMUNITY_COMMENT,
    payload: {
      communityId,
      localCommentId,
      error: err
    }
  };
}

function postPledge(communityId) {
  return {
    type: POST_PLEDGE,
    payload: {
      communityId
    }
  };
}

function failedPostPledge(communityId, error) {
  return {
    type: FAILED_POST_PLEDGE,
    payload: {
      communityId,
      error
    }
  };
}

function successfullyPostedPledge(communityId, amount, currencyCode) {
  return {
    type: SUCCESSFULLY_POSTED_PLEDGE,
    payload: {
      communityId,
      amount,
      currencyCode
    }
  };
}

export function getComments(token, id) {
  return (dispatch) => {
    dispatch(requestComments(id));

    Api.communityComments(token, id).then((comments) => {
      dispatch(receivedComments(id, comments));
    }).catch((err) => {
      dispatch(failedComments(err));
    });
  };
}

export function postComment(token, user, communityId, message) {
  return (dispatch) => {
    const localCommentId = -Date.now();

    const localComment = Map({
      id: localCommentId,
      date: moment(),
      user,
      message
    });

    dispatch(postCommunityComment(communityId, localComment));

    Api.postComment(token, communityId, message).then((remoteCommentId) => {
      dispatch(successfullyPostedCommunityComment(communityId, localCommentId, remoteCommentId.get('id')));
    }).catch((err) => {
      dispatch(failedPostCommunityComment(communityId, localCommentId, err));
    });
  };
}

export function get(token, id) {
  return (dispatch) => {
    dispatch(requestCommunity(id));

    Api.community(token, id).then((community) => {
      dispatch(receivedCommunity(community));
    }).catch((err) => {
      dispatch(failedCommunity(err));
    });
  };
}

export function showCommunity(token, community) {
  return (dispatch) => {
    dispatch(receivedCommunity(community));

    const quitterId = community.getIn([ 'quitter', 'id' ]);
    dispatch(
      pushPath(NamedRoutes.withParams(NamedRoutes.COMMUNITY, { quitterId }))
    );

    dispatch(getComments(token, quitterId));
  };
}

export function showLearnHowCampaignsWork() {
  return {
    type: SHOW_LEARN_HOW_CAMPAIGNS_WORK,
    payload: { }
  };
}

export function hideLearnHowCampaignsWork() {
  return {
    type: HIDE_LEARN_HOW_CAMPAIGNS_WORK,
    payload: { }
  };
}

export function showPledge() {
  return {
    type: SHOW_PLEDGE,
    payload: { }
  };
}

export function hidePledge() {
  return {
    type: HIDE_PLEDGE,
    payload: { }
  };
}

export function pledge(token, communityId, pledgeInfo) {
  const { cardNumber, cvc, expirationMonth, expirationYear, amountCents, currencyCode } = pledgeInfo;
  return (dispatch) => {
    dispatch(postPledge(communityId));

    stripe.getCardToken({
      number: cardNumber,
      cvc,
      exp_month: expirationMonth,
      exp_year: expirationYear
    }).then((stripeToken) => {
      return Api.pledge(token, communityId, amountCents, currencyCode, stripeToken);
    }).then(() => {
      dispatch(successfullyPostedPledge(communityId, amountCents, currencyCode));
    }).catch((err) => {
      dispatch(failedPostPledge(communityId, err));
    });
  };
}
