import React, { Component } from 'react';
import { Defaults } from 'constants';
import classnames from 'classnames';
import { propTypes } from '../lib';

import styles from '../styles/profile-pic.scss';

@propTypes({
  className: propTypes.string,
  url: propTypes.string,
  size: propTypes.oneOf([ 'small', 'medium', 'large' ])
})
export default class ProfilePic extends Component {
  render() {
    const { className, url, size } = this.props;
    const imgUrl = url && Defaults.profilePicUrl;
    const cls = styles[size];

    return (
      <img className={classnames(cls, className)}
           src={imgUrl}
           role="presentation" />
    );
  }
}
