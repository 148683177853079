import React, { Component } from 'react';
import Comment from './comment';
import { propTypes } from '../../lib';

@propTypes({
  community: propTypes.community.isRequired
})
export default class CommentInput extends Component {
  render() {
    const { community } = this.props;

    return (
      <div className={this.props.className}>
        {community.get('comments').map((comment) => (
          <Comment key={comment.get('id')}
                   comment={comment} />
        ))}
      </div>
    );
  }
}
