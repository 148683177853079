import React, { Component } from 'react';
import classnames from 'classnames';
import { propTypes } from '../../lib';
import { CampaignRewardTypes } from 'constants';

import styles from '../../styles/campaign-use-of-money.scss';

@propTypes({
  campaign: propTypes.campaign.isRequired
})
export default class UseOfMoney extends Component {
  render() {
    const { campaign } = this.props;

    return campaign.get('rewardType') === CampaignRewardTypes.CHARITY
      ? this.renderCharity(campaign)
      : this.renderMoney(campaign);
  }

  renderCharity(campaign) {
    const charity = campaign.get('charity');

    return (
      <div className={classnames(styles.body, this.props.className)}>
        <div className={styles.title}>How the money will be used</div>
        <img className={styles.charityImg}
             src={charity.get('imageUrl')}
             role="presentation"/>
        <div className={styles.name}>{charity.get('name')}</div>
      </div>
    );
  }

  renderMoney(campaign) {
    const rewardDescription = campaign.get('rewardDescription');

    if ( !rewardDescription ) {
      return null;
    }

    return (
      <div className={classnames(styles.body, this.props.className)}>
        <div className={styles.title}>How the money will be used</div>
        <div className={styles.description}>{rewardDescription}</div>
      </div>
    );
  }
}
