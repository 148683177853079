import { createStore, applyMiddleware, compose } from 'redux';
import { syncReduxAndRouter } from 'redux-simple-router';
import { devTools, history } from './lib';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const finalCreateStore = compose(
  applyMiddleware(thunk),
  devTools ? devTools.instrument() : (x) => x
)(createStore);

const store = finalCreateStore(rootReducer);

syncReduxAndRouter(history, store);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers');
    store.replaceReducer(nextRootReducer);
  });
}

export default store;
