import { Map } from 'immutable';
import { ActionTypes } from '../constants';

const initialState = Map({
  redirectPath: null
});

export default function nav(state = initialState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.REQUIRE_LOGIN:
      return state.merge({ redirectPath: payload.redirectPath });
    case ActionTypes.HANDLED_LOGIN_REDIRECT:
      return state.merge({ redirectPath: null });
    default:
      return state;
  }
}
