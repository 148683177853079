exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".communities__container___3otgs {\n  width: 100%;\n  padding-top: 130px; }\n\n.communities__body___2U075 { }\n\n.communities__title___2eBqM {\n  width: 100%;\n  text-align: center;\n  margin-bottom: 15px; }\n\n.communities__loading___VEBn4 {\n  width: 50px;\n  height: 50px;\n  line-height: 50px;\n  margin: auto; }\n\n.communities__noCommunities___2Xj-C {\n  width: 100%;\n  text-align: center; }\n\n.communities__thumbnail___2Bief {\n  margin-left: 20px;\n  margin-bottom: 20px;\n  max-width: 333px; }\n\n@media " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["mobile"] + " {\n  .communities__body___2U075 {\n    padding-right: 20px;\n    padding-left: 20px; }\n  .communities__thumbnail___2Bief {\n    max-width: initial !important;\n    width: 100% !important;\n    margin: 20px auto; } }\n\n.communities__error___1ELqB {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"bp-mobile": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["mobile"] + "",
	"container": "communities__container___3otgs",
	"body": "communities__body___2U075 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer"] + "",
	"title": "communities__title___2eBqM " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["popupTitle"] + "",
	"loading": "communities__loading___VEBn4",
	"noCommunities": "communities__noCommunities___2Xj-C " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["bodyEmphasis"] + "",
	"thumbnail": "communities__thumbnail___2Bief " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["thirdStackLeft"] + "",
	"error": "communities__error___1ELqB"
};