import React, { Component } from 'react';
import { propTypes, NameUtils } from '../../lib';
import { CampaignRewardTypes } from 'constants';

import styles from '../../styles/campaign-how-it-works.scss';

@propTypes({
  community: propTypes.community
})
export default class HowItWorks extends Component {
  render() {
    const { community } = this.props;

    const name = NameUtils.firstOrEmail(community.get('quitter'));
    const isCharity = community.getIn([ 'campaign', 'rewardType' ]) === CampaignRewardTypes.CHARITY;

    return (
      <ul className={styles.body}>
        <li>
          You pledge an amount of money to help&nbsp;
          <span className={styles.emphasis}>{`${name} quit smoking. `}</span>
          Science has shown that this is a highly effective means of motivating people to quit smoking.
        </li>
        <li>
          We keep your pledge secure on our servers but don't charge you until {name} actually quits.
        </li>
        <li>
          We help <span className={styles.emphasis}>{name}</span> quit smoking through our fun, science-based quitting program.
        </li>
        <li>
          After going 3 months without smoking, {name} will certify as a quitter (remember, with smoking, being a quitter is a very good thing).
        </li>
        <li>
          If successful, we will charge you for your pledge and send the money directly to&nbsp;
          {isCharity
           ? (
            <span>the charity <span className={styles.emphasis}>{name}</span> has chosen</span>
           ) : (
            <span className={styles.emphasis}>{name}</span>
           )
          }.
        </li>
        <li>
          During the campaign, <span className={styles.emphasis}>{name}</span> may have some minor slips. We never give up on anyone and we urge you to do the same. Quitting smoking is among the most difficult journeys of anyone's life so a few slip ups are common and part of the process.
        </li>
        <li>
          You can withdraw your pledge at any time. You should only do this if you no longer want to support <span className={styles.emphasis}>{name}</span>.
        </li>
      </ul>
    );
  }
}
