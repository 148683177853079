import { List } from 'immutable';

export function firstOrEmail(person) {
  return person.get('firstName') || emailName(person.get('email'));
}

export function fullOrEmail(person) {
  const first = person.get('firstName');
  const last = person.get('lastName');

  return first || last ? joinWithoutNulls(' ', first, last) : emailName(person.get('email'));
}

function emailName(email) {
  return email.split('@')[0];
}

function joinWithoutNulls(sep, ...items) {
  return List(items).filter((x) => !!x).join(sep);
}
