import React, { Component } from 'react';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { CampaignTypes, CampaignRewardTypes } from 'constants';

const { PropTypes } = React;

export default function propTypes(types) {
  return (WrappedComponent) => (
    class PropTypesWrapper extends Component {
      static propTypes = {
        ...types
      };

      render() {
        return React.createElement(WrappedComponent, this.props);
      }
    }
  );
}

propTypes.Immutable = ImmutablePropTypes;
Object.assign(propTypes, PropTypes);

const userPropType = ImmutablePropTypes.contains({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string.isRequired,
  profilePicUrl: PropTypes.string
});

const campaignTypePropType = PropTypes.oneOf(CampaignTypes.all.toArray());

const campaignRewardTypePropType = PropTypes.oneOf(CampaignRewardTypes.all.toArray());

const currencyCodePropType = PropTypes.oneOf([ 'USD' ]);

const commentPropType = ImmutablePropTypes.contains({
  id: PropTypes.number.isRequired,
  user: userPropType.isRequired,
  date: PropTypes.instanceOf(moment).isRequired,
  message: PropTypes.string.isRequired
});

const campaignPropType = ImmutablePropTypes.contains({
  type: campaignTypePropType,
  rewardType: campaignRewardTypePropType,
  isVerifiedByHonesty: PropTypes.bool.isRequired,
  rewardDescription: PropTypes.string,
  selfPledge: ImmutablePropTypes.contains({
    amount: PropTypes.number,
    currencyCode: currencyCodePropType
  }),
  currentUserPledge: ImmutablePropTypes.contains({
    amount: PropTypes.number,
    currencyCode: currencyCodePropType
  }),
  startDate: PropTypes.instanceOf(moment).isRequired,
  durationDays: PropTypes.number,
  totalPledged: ImmutablePropTypes.contains({
    amount: PropTypes.number,
    currencyCode: currencyCodePropType
  }),
  charity: ImmutablePropTypes.contains({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired
  })
});

Object.assign(propTypes, {
  user: userPropType,
  quitter: userPropType,
  campaign: campaignPropType,
  currencyCode: currencyCodePropType,
  comment: commentPropType,
  community: ImmutablePropTypes.contains({
    quitter: userPropType,
    isPrivate: PropTypes.bool.isRequired,
    supporters: ImmutablePropTypes.listOf(userPropType),
    comments: ImmutablePropTypes.listOf(commentPropType),
    campaign: campaignPropType
  })
});
