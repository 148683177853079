import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavActions } from '../actions';

@connect((state) => ({
  sessionToken: state.userData.get('sessionToken'),
  loading: state.userData.get('loading'),
  routing: state.routing
}))
export default class RequireAuth extends Component {
  componentWillMount() {
    this.redirectIfUnauthorized(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.redirectIfUnauthorized(nextProps);
  }

  render() {
    return (
      <div>
        {this.shouldRenderChild()
         ? this.props.children
         : null
        }
      </div>
    );
  }

  redirectIfUnauthorized(props) {
    const { dispatch, routing } = props;
    if ( this.doesRequireAuth(props) ) {
      dispatch(NavActions.requireLogin(routing.path));
    }
  }

  doesRequireAuth(props) {
    const { loading, sessionToken } = props;
    return !loading && !sessionToken;
  }

  shouldRenderChild() {
    const { loading, sessionToken } = this.props;
    return !loading && sessionToken;
  }
}
