import { ActionTypes } from '../constants';
import { pushPath } from 'redux-simple-router';
import { NamedRoutes } from '../lib';

const { REQUIRE_LOGIN, HANDLED_LOGIN_REDIRECT } = ActionTypes;

function _requireLogin(redirectPath) {
  return {
    type: REQUIRE_LOGIN,
    payload: {
      redirectPath
    }
  };
}

function handledLoginRedirect() {
  return {
    type: HANDLED_LOGIN_REDIRECT
  };
}

export function requireLogin(redirectPath) {
  return (dispatch) => {
    dispatch(_requireLogin(redirectPath));
    dispatch(pushPath(NamedRoutes.LOGIN));
  };
}

export function handleLoginRedirect(nav) {
  const redirectPath = nav && nav.get('redirectPath');
  return (dispatch) => {
    dispatch(pushPath(redirectPath || NamedRoutes.DEFAULT_POST_LOGIN_ROUTE));
    dispatch(handledLoginRedirect());
  };
}
