exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");

// Module
exports.push([module.id, ".loading__loading___2bE_K {\n  display: inline-block;\n  width: 100%;\n  height: 100%; }\n", ""]);

// Exports
exports.locals = {
	"loading": "loading__loading___2bE_K " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["loading"] + ""
};