import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { history } from './lib';
import store from './store';
import Routes from './community-routes';
import DevTools from './lib/dev-tools';

render(
  <div>
    <Provider store={store}>
      <div>
        <Router history={history}>
          {Routes}
        </Router>
        {DevTools ? <DevTools /> : null}
      </div>
    </Provider>
  </div>,
  document.getElementById('content')
);
