import { combineReducers } from 'redux';
import { routeReducer } from 'redux-simple-router';

import community from './community';
import user from './user';
import communities from './communities';
import nav from './nav';

const rootReducer = combineReducers({
  nav,
  userData: user,
  communityData: community,
  communitiesData: communities,
  routing: routeReducer
});

export default rootReducer;
