/* eslint-disable jsx-a11y/no-static-element-interactions*/
/*
  TODO:  There is a linting error when providing interaction props to (div, th, td) elements,
         We don't want to replace these elements to (a, button), as some are styled for the specific element.
         this is supported with jsx-a11y version 6.0.2 when we specify a role to an element
         https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-static-element-interactions.md
         Remove disable line above after updating @clicktheraeutics-eslint-config-ct-base library to support jsx-a11y>6.02
*/
import React, { Component } from 'react';
import classnames from 'classnames';
import MaskedInput from 'react-maskedinput-plus';
import creditcards from 'creditcards';
import { propTypes, NameUtils, MoneyUtils } from '../../lib';
import ErrorMsg from '../error-msg';

import styles from '../../styles/campaign-pledge.scss';

@propTypes({
  quitter: propTypes.quitter,
  pledgePostInProgress: propTypes.bool.isRequired,
  pledgePostError: propTypes.instanceOf(Error),
  onPledge: propTypes.func.isRequired,
  onPledgeDone: propTypes.func.isRequired
})
export default class Pledge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postedPledge: false,
      email: '',
      password: '',
      passwordConfirmation: '',
      cardNumber: '',
      expirationDate: '',
      cvc: '',
      amount: null,
      customAmount: null
    };
  }

  render() {
    const { pledgePostInProgress, quitter, pledgePostError } = this.props;
    const { email, password, passwordConfirmation, cardNumber, expirationDate, cvc, amount, customAmount, postedPledge } = this.state;
    const cardType = creditcards.card.type(cardNumber, true);
    const cvcPattern = cardType === 'American Express' ? '1111' : '111';
    const defaultAmounts = [ 25, 50, 100, 500 ];
    const btnClasses = classnames({
      [styles.button]: true,
      [styles.loading]: pledgePostInProgress
    });
    const cnames = classnames; // working around a weird bug in transpiling

    const getStripeError = (getter) => pledgePostError && pledgePostError[getter] && pledgePostError[getter]() ? pledgePostError : null;

    const cardError = getStripeError('cardError');
    const expirationError = getStripeError('expirationError');
    const cvcError = getStripeError('cvcError');
    const foundKnownError = cardError || expirationError || cvcError;
    const extraError = pledgePostError && !foundKnownError && pledgePostError;

    if ( postedPledge && !pledgePostInProgress && !pledgePostError ) {
      return (
        <div className={styles.container}>
          <div className={styles.thankYou}>
            {`${NameUtils.firstOrEmail(quitter)} thanks you for your support!`}
          </div>
          <div className={styles.ribbon} />
          <button className={styles.button}
                  onClick={this.onPledgeDone.bind(this)}>
            Got it!
          </button>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {this.renderLoginOrSignup(email, password, passwordConfirmation)}
        <div className={styles.amounts}>
          {defaultAmounts.map((defaultAmount) => {
            const isSelected = amount === defaultAmount;
            const amtStyles = cnames({
              [styles.normalAmount]: !isSelected,
              [styles.selectedNormalAmount]: isSelected
            });

            return (
              <div key={defaultAmount}
                   className={amtStyles}
                   onClick={this.onSelectDefaultAmount.bind(this, defaultAmount)}>
                {`$${defaultAmount}`}
              </div>
            );
          })}
          {customAmount === null
           ? (
            <div className={styles.customAmount}
                 onClick={this.onSelectCustomAmount.bind(this)}>
              Custom
            </div>
           ) : (
            <MaskedInput className={styles.selectedCustomAmount}
                         value={customAmount}
                         onChange={this.onUpdate.bind(this, 'customAmount')}
                         placeholderChar=" "
                         pattern="$1111" />
           )
          }
        </div>
        <MaskedInput className={styles.input}
                     placeholder="Card Number"
                     value={cardNumber}
                     onChange={this.onUpdate.bind(this, 'cardNumber')}
                     placeholderChar=" "
                     pattern="1111 1111 1111 1111" />
        <ErrorMsg error={cardError}>
          Please enter a valid card number
        </ErrorMsg>
        <div className={styles.full}>
          <div className={styles.half}>
            <MaskedInput className={styles.input}
                         placeholder="MM/DD"
                         value={expirationDate}
                         onChange={this.onUpdate.bind(this, 'expirationDate')}
                         placeholderChar=" "
                         pattern="11/11" />
            <ErrorMsg error={expirationError}>
              Please enter your card's expiration date as MM/YY
            </ErrorMsg>
          </div>
          <div className={styles.half}>
            <MaskedInput className={styles.input}
                         placeholder="CVC"
                         value={cvc}
                         onChange={this.onUpdate.bind(this, 'cvc')}
                         placeholderChar=" "
                         pattern={cvcPattern} />
            <ErrorMsg error={cvcError}>
              Please enter your card's security code
            </ErrorMsg>
          </div>
        </div>
        <ErrorMsg error={extraError} />
        <button onClick={this.onPledge.bind(this)}
                className={btnClasses}>
          Pledge
        </button>
      </div>
    );
  }

  renderLoginOrSignup(ignoredEmail, ignoredPassword, ignoredPasswordConfirmation) {
    return null;

    // TODO: render login or signup if you're not properly logged in
    /*
      return (
        <div>
          <input type="email"
                 placeholder="Email"
                 value={email}
                 onChange={this.onUpdate.bind(this, 'email')}
                 className={styles.input} />
          <input type="password"
                 placeholder="Password"
                 value={password}
                 onChange={this.onUpdate.bind(this, 'password')}
                 className={styles.input} />
          <input type="password"
                 placeholder="Confirm Password"
                 value={passwordConfirmation}
                 onChange={this.onUpdate.bind(this, 'passwordConfirmation')}
                 className={styles.lastInput} />
          <hr />
        </div>
      );
    */
  }

  onUpdate(key, evt) {
    this.setState({
      [key]: evt.target.value
    });
  }

  onSelectDefaultAmount(amount) {
    this.setState({
      amount,
      customAmount: null
    });
  }

  onSelectCustomAmount() {
    this.setState({
      amount: null,
      customAmount: ''
    });
  }

  onPledge() {
    if ( this.props.pledgePostInProgress ) {
      // prevent double taps
      return;
    }

    this.setState({
      postedPledge: true
    });

    // TODO: check password validation
    const state = this.state;
    const [ expirationMonth, expirationYear ] = this.state.expirationDate.split('/');

    this.props.onPledge({
      email: state.email,
      password: state.password,
      cardNumber: state.cardNumber,
      expirationMonth,
      expirationYear,
      cvc: state.cvc,
      currencyCode: MoneyUtils.userCurrencyCode(),
      amountCents: this.amountCents()
    });
  }

  onPledgeDone() {
    this.props.onPledgeDone();
  }

  amountCents = () => {
    const { amount, customAmount } = this.state;
    const strAmount = amount || (customAmount && /(\d+(.\d+)?)/.exec(customAmount));
    return 100 * parseInt(strAmount, 10);
  };
}
