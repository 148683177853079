exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("images/icon_settings_n.png"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("images/icon_settings_h.png"));

// Module
exports.push([module.id, ".campaign-nav-subheader__campaignHeader___clJuM {\n  width: 100%; }\n\n.campaign-nav-subheader__campaignTitle___3BMGn {\n  position: absolute;\n  text-align: center;\n  color: #ffffff;\n  line-height: 50px;\n  width: 100%; }\n\n.campaign-nav-subheader__settingsContainer___3TNwT {\n  position: relative;\n  max-width: " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["max-content-width"] + ";\n  width: 100%;\n  margin: 0 auto;\n  line-height: 50px; }\n\n.campaign-nav-subheader__settingsButton___3bgTB {\n  float: right;\n  line-height: 50px;\n  cursor: pointer;\n  margin-right: 10px; }\n  .campaign-nav-subheader__settingsButton___3bgTB .campaign-nav-subheader__settingsIcon___15i_S {\n    position: relative;\n    display: inline-block;\n    background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n    width: 25px;\n    height: 25px;\n    top: 8px; }\n\n.campaign-nav-subheader__settingsButton___3bgTB:hover .campaign-nav-subheader__settingsIcon___15i_S {\n  background-image: url(" + ___CSS_LOADER_URL___1___ + "); }\n\n@media " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["mobile"] + " {\n  .campaign-nav-subheader__settingsButtonText___2pZ49 {\n    display: none; } }\n", ""]);

// Exports
exports.locals = {
	"max-content-width": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["max-content-width"] + "",
	"bp-mobile": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["mobile"] + "",
	"campaignHeader": "campaign-nav-subheader__campaignHeader___clJuM " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["header"] + " " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["clearfix"] + "",
	"campaignTitle": "campaign-nav-subheader__campaignTitle___3BMGn " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["header"] + "",
	"settingsContainer": "campaign-nav-subheader__settingsContainer___3TNwT",
	"settingsButton": "campaign-nav-subheader__settingsButton___3bgTB " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["textButton4"] + "",
	"settingsIcon": "campaign-nav-subheader__settingsIcon___15i_S",
	"settingsButtonText": "campaign-nav-subheader__settingsButtonText___2pZ49"
};