export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const FORGOT_PASSWORD = '/forgot-password';
export const COMMUNITIES = '/communities';
export const COMMUNITY = '/communities/:quitterId';

export function withParams(route, params) {
  return Object.keys(params).reduce((r, param) => (
    r.replace(`:${param}`, params[param])
  ), route);
}

export const DEFAULT_POST_LOGIN_ROUTE = COMMUNITIES;
