exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".campaign-use-of-money__body___2oD3Q {\n  text-align: center;\n  padding: 15px; }\n\n.campaign-use-of-money__title___3lS7p {\n  margin-bottom: 20px; }\n\n.campaign-use-of-money__charityImg___2eohw {\n  width: 88px;\n  height: 50px;\n  margin-bottom: 10px; }\n\n.campaign-use-of-money__name___VShiS { }\n\n.campaign-use-of-money__description___20So1 { }\n", ""]);

// Exports
exports.locals = {
	"body": "campaign-use-of-money__body___2oD3Q " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["box"] + "",
	"title": "campaign-use-of-money__title___3lS7p " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["title"] + "",
	"charityImg": "campaign-use-of-money__charityImg___2eohw",
	"name": "campaign-use-of-money__name___VShiS " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["body"] + "",
	"description": "campaign-use-of-money__description___20So1 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["boxBody"] + ""
};