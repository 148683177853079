import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const initialState = Map({
  loading: false,
  error: null,
  communities: null
});

export default function communities(state = initialState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.REQUEST_COMMUNITIES:
      return state.merge({
        loading: true,
        error: null,
        communities: null
      });
    case ActionTypes.RECEIVED_COMMUNITIES:
      return state.merge({
        loading: false,
        error: null,
        communities: payload.communities
      });
    case ActionTypes.FAILED_COMMUNITIES:
      logger.error('Failed to get communities', payload.error);
      return state.merge({
        loading: false,
        error: payload.error,
        communities: null
      });
    case ActionTypes.RECEIVED_COMMUNITY_COMMENTS:
      const communityIdx = state.get('communities').findIndex((c) => (
        c.getIn([ 'quitter', 'id' ]) === payload.communityId
      ));
      return communityIdx >= 0
        ? state.mergeIn([ 'communities', communityIdx, 'comments' ], payload.comments)
        : state;
    default:
      return state;
  }
}
