import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as HttpStatus from 'http-status-codes';
import { Link } from 'react-router';
import { NamedRoutes } from '../lib';
import { Nav, ErrorMsg } from '../components';
import { UserActions } from '../actions';

import styles from '../styles/signup.scss';

@connect((state) => ({
  userData: state.userData,
  nav: state.nav
}))
export default class SignupPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      passwordConrimation: ''
    };

    this.onUpdateEmail = this.update.bind(this, 'email');
    this.onUpdatePassword = this.update.bind(this, 'password');
    this.onUpdatePasswordConfirmation = this.update.bind(this, 'passwordConfirmation');
    this.onSignup = () => {
      this.props.dispatch(UserActions.signup(this.state.email, this.state.password, this.state.passwordConfirmation, this.props.nav));
    };
  }

  render() {
    const { userData } = this.props;
    const loading = userData.get('loading');
    const signupClasses = {
      [styles.signupButton]: true,
      [styles.loading]: loading
    };
    const error = userData.get('signupError');

    return (
      <div className={styles.container}>
        <Nav user={null} />
        <div className={styles.body}>
          <div className={styles.signup}>
            <div className={styles.title}>Sign up</div>
            <input type="email"
                   className={styles.input}
                   placeholder="Email"
                   value={this.state.email}
                   onChange={this.onUpdateEmail} />
            <input type="password"
                   className={styles.input}
                   placeholder="Password"
                   value={this.state.password}
                   onChange={this.onUpdatePassword} />
            <input type="password"
                   className={styles.input}
                   placeholder="Confirm Password"
                   value={this.state.passwordConfirmation}
                   onChange={this.onUpdatePasswordConfirmation} />
            <ErrorMsg error={error}
                      msgByStatus={{
                        [HttpStatus.UNPROCESSABLE_ENTITY]: (err) => (
                          err.data && err.data.errors ? err.data.errors.join(', ') : 'Please enter a valid email and password'
                        )
                      }} />
            <button className={classnames(signupClasses)}
                    onClick={this.onSignup}>
              Sign up
            </button>
          </div>

          <p className={styles.loginContainer}>
            Already have an account?&nbsp;
            <Link to={NamedRoutes.LOGIN}
                  className={styles.loginLink}>
              Log in
            </Link>
          </p>
        </div>
      </div>
    );
  }

  update(key, evt) {
    this.setState({ [key]: evt.target.value });
  }
}
