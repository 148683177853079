import React, { Component } from 'react';
import { propTypes } from '../lib';
import ProfilePic from './profile-pic';

import classnames from 'classnames';
import styles from '../styles/nav.scss';

@propTypes({
  user: propTypes.user,
  onLogout: propTypes.func
})
export default class Nav extends Component {
  render() {
    const { user, onLogout } = this.props;

    return (
      <nav className={classnames('navbar', 'navbar-default', 'navbar-fixed-top')}>
        <div className={classnames('clearfix', styles.clickotineHeader)}>
          <div className={styles.headerLeft}>
            <div className={classnames('navbar-brand', styles.logo)} />
          </div>
          <div className={styles.topInfo}>
            <span>{'23,452 campaigns are opened in the Clickotine world'}</span>
          </div>
          <div className={styles.headerRight}>
            {user
             ? (
               <div>
                 <input type="button"
                        value="Log out"
                        onClick={onLogout}
                        className={classnames(styles.logoutLink)} />
                 <ProfilePic size="medium"
                             url={user.get('profilePicUrl')} />
               </div>
             ) : null}
          </div>
        </div>
        {this.props.children}
      </nav>
    );
  }
}
