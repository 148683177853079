exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");

// Module
exports.push([module.id, ".community__container___2T-DU {\n  width: 100%;\n  padding-top: 100px; }\n\n.community__body___2KpMy { }\n\n.community__fullContainer___j-Oo8 {\n  width: 100%;\n  height: 100%; }\n\n.community__primaryContainer___thega {\n  padding: 15px; }\n\n.community__secondaryContainer___1GSaV {\n  padding: 15px; }\n\n.community__useOfMoney___60v99 {\n  margin-bottom: 15px; }\n\n.community__commentList___3eMHW {\n  margin-top: 50px; }\n\n.community__modalBackdrop___2Ax0f { }\n\n.community__loading___15PbD {\n  width: 50px;\n  height: 50px;\n  line-height: 50px;\n  margin: auto; }\n\n.community__error___3N0SH {\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"bp-mobile": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/breakpoints.scss").locals["mobile"] + "",
	"container": "community__container___2T-DU",
	"body": "community__body___2KpMy " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer"] + "",
	"fullContainer": "community__fullContainer___j-Oo8",
	"primaryContainer": "community__primaryContainer___thega " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["twoThirdsStackRight"] + "",
	"secondaryContainer": "community__secondaryContainer___1GSaV " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["thirdStackRight"] + "",
	"useOfMoney": "community__useOfMoney___60v99",
	"commentList": "community__commentList___3eMHW",
	"modalBackdrop": "community__modalBackdrop___2Ax0f " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["modalBackdrop"] + "",
	"loading": "community__loading___15PbD",
	"error": "community__error___3N0SH"
};