import React, { Component } from 'react';
import { propTypes, NameUtils } from '../../lib';

import styles from '../../styles/campaign-nav-subheader.scss';

@propTypes({
  quitter: propTypes.user
})
export default class NavSubheader extends Component {
  render() {
    const { quitter } = this.props;

    return (
      <div className={styles.campaignHeader}>
        <div className={styles.campaignTitle}>{`${NameUtils.firstOrEmail(quitter)}'s Quit Campaign`}</div>
      </div>
    );
  }
}
