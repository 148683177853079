import React, { Component } from 'react';
import classnames from 'classnames';
import ProfilePic from '../profile-pic';
import { NameUtils, propTypes } from '../../lib';
import ErrorMsg from '../error-msg';

import styles from '../../styles/comment-input.scss';

@propTypes({
  user: propTypes.user.isRequired,
  community: propTypes.community.isRequired,
  postInProgress: propTypes.bool.isRequired,
  postError: propTypes.instanceOf(Error),
  onPostComment: propTypes.func.isRequired
})
export default class CommentInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: ''
    };

    this.onUpdateComment = (evt) => {
      this.setState({
        comment: evt.target.value
      });
    };

    this.onPostComment = () => {
      this.props.onPostComment(this.state.comment);
      this.setState({
        comment: ''
      });
    };
  }

  render() {
    const { user, community, postInProgress, postError } = this.props;
    const quitter = community.get('quitter');
    const buttonClasses = classnames({
      [styles.loading]: postInProgress,
      [styles.send]: true
    });

    return (
      <div className={classnames(styles.body, this.props.className)}>
        <div>
          <ProfilePic url={user.get('profilePicUrl')}
                      size="small" />
          <span className={styles.name}>{user.get('email')}</span>
        </div>
        <textarea className={styles.comment}
                  value={this.state.comment}
                  onChange={this.onUpdateComment}
                  placeholder={`Give ${NameUtils.firstOrEmail(quitter)} some encouragement!`}/>
        <ErrorMsg error={postError} />
        <button type="submit"
                className={buttonClasses}
                onClick={this.onPostComment}>
          Send
        </button>
      </div>
    );
  }
}
