exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".community-thumbnail__container___31Z0j {\n  height: auto;\n  padding: 20px 30px;\n  text-align: center; }\n\n.community-thumbnail__name___3lyEl { }\n\n.community-thumbnail__email___37Yj1 { }\n\n.community-thumbnail__infoValue___M_DZ4 { }\n\n.community-thumbnail__infoLabel___1GgFN { }\n\n.community-thumbnail__button___2vQos {\n  display: block;\n  width: 100%;\n  margin-top: 30px; }\n", ""]);

// Exports
exports.locals = {
	"container": "community-thumbnail__container___31Z0j " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxPledge"] + "",
	"name": "community-thumbnail__name___3lyEl " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["nameInfo"] + "",
	"email": "community-thumbnail__email___37Yj1 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["emailInfo"] + "",
	"infoValue": "community-thumbnail__infoValue___M_DZ4 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignListValue"] + "",
	"infoLabel": "community-thumbnail__infoLabel___1GgFN " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignListInfo"] + "",
	"button": "community-thumbnail__button___2vQos " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton1"] + ""
};