/* eslint no-console: 0 */

const logger = {
  error(msg, ...args) {
    if ( console && console.error ) {
      console.error(msg, ...args); // TODO: eventually, send this to server
    }
  },
  info(msg, ...args) {
    if ( console && console.log ) {
      console.log(msg, ...args);
    }
  }
};

export default logger;
