/* global __BUILD_ENV__, __PRODUCTION_BUILD_ENV__, __BETA_BUILD_ENV__, __ALPHA_BUILD_ENV__, __DEVELOPMENT_BUILD_ENV__, __LOCAL_BUILD_ENV__, __DEV_API_ROOT__ */

const config = getConfig();

export default config;

function getConfig() {
  const TEST_STRIPE_PUBLISHABLE_KEY = 'pk_test_8xTnOzJq8V1qe3W7Yzt7T7Ns';
  const LIVE_STRIPE_PUBLISHABLE_KEY = 'pk_live_fViHQCFSk21jlzvR6dKXh0l7';

  const BASE_DEV_CONFIG = {
    stripePublishableKey: TEST_STRIPE_PUBLISHABLE_KEY
  };

  switch ( __BUILD_ENV__ ) {
    case __PRODUCTION_BUILD_ENV__:
      return {
        apiRoot: 'https://ct101-api.clicktherapeutics.com/api',
        stripePublishableKey: LIVE_STRIPE_PUBLISHABLE_KEY
      };
    case __BETA_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.beta.clicktherapeuticsdev.com/api'
      }, BASE_DEV_CONFIG);
    case __ALPHA_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.alpha.clicktherapeuticsdev.com/api'
      }, BASE_DEV_CONFIG);
    case __DEVELOPMENT_BUILD_ENV__:
      return Object.assign({
        apiRoot: 'https://ct101-api.development.clicktherapeuticsdev.com/api'
      }, BASE_DEV_CONFIG);
    case __LOCAL_BUILD_ENV__:
      return Object.assign({
        apiRoot: __DEV_API_ROOT__ + '/api'
      }, BASE_DEV_CONFIG);
    default:
      throw new Error('Unknown environment: ' + __BUILD_ENV__);
  }
}
