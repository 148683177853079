import { ActionTypes } from '../constants';
import { Api } from '../lib';

const { REQUEST_COMMUNITIES, RECEIVED_COMMUNITIES, FAILED_COMMUNITIES } = ActionTypes;

function requestCommunities() {
  return {
    type: REQUEST_COMMUNITIES
  };
}

function receivedCommunities(communities) {
  return (dispatch) => {
    dispatch({
      type: RECEIVED_COMMUNITIES,
      payload: {
        communities
      }
    });
  };
}

function failedCommunities(err) {
  return {
    type: FAILED_COMMUNITIES,
    payload: {
      error: err
    }
  };
}

export function get(token) {
  return (dispatch) => {
    dispatch(requestCommunities());

    Api.communities(token).then((communities) => {
      dispatch(receivedCommunities(communities));
    }).catch((err) => {
      dispatch(failedCommunities(err));
    });
  };
}
