exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("images/campaign_info_bg.png"));

// Module
exports.push([module.id, ".campaign-header__campaignHeader___3VqoC {\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-position: 50% 0;\n  background-repeat: no-repeat;\n  width: 100%;\n  min-height: 296px;\n  text-align: center;\n  padding-top: 40px; }\n\n.campaign-header__quitterName___1QxqZ { }\n\n.campaign-header__raisingCampaign___1vJgy { }\n\n.campaign-header__campaignType___1TNJO { }\n\n.campaign-header__campaignDetail___3KToO { }\n\n.campaign-header__glowingProfilePic___3QeH- {\n  -webkit-box-shadow: 0 1px 0px 1px #02F0FD;\n  box-shadow: 0 1px 0px 1px #02F0FD; }\n\n.campaign-header__campaignInfo___3BFUY {\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"campaignHeader": "campaign-header__campaignHeader___3VqoC",
	"quitterName": "campaign-header__quitterName___1QxqZ " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignTitleEmphasis"] + "",
	"raisingCampaign": "campaign-header__raisingCampaign___1vJgy " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignTitle"] + "",
	"campaignType": "campaign-header__campaignType___1TNJO " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignTitleEmphasis"] + "",
	"campaignDetail": "campaign-header__campaignDetail___3KToO " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["campaignDetail"] + "",
	"glowingProfilePic": "campaign-header__glowingProfilePic___3QeH-",
	"campaignInfo": "campaign-header__campaignInfo___3BFUY"
};