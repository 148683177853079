import { fromJS } from 'immutable';
import Cookie from 'an-cookie';
import logger from './logger';

const USER_KEY = 'user';

export const user = {
  get() {
    const initialUserJson = Cookie(USER_KEY, null);
    if ( !initialUserJson ) {
      return null;
    }

    try {
      const parsed = JSON.parse(initialUserJson);
      return {
        sessionToken: parsed.sessionToken,
        user: fromJS(parsed.user)
      };
    } catch ( err ) {
      logger.error('Failed to parse cookie', initialUserJson, err);
      return null;
    }
  },

  set(u, token) {
    const payload = {
      user: u.toJS(),
      sessionToken: token
    };
    Cookie.set(USER_KEY, JSON.stringify(payload), 1 /* day */, '/', null, true);
  },

  clear() {
    Cookie.set(USER_KEY, 'null', new Date(), '/', null, true);
  }
};
