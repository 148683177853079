// nav actions
export const REQUIRE_LOGIN = 'REQUIRE_LOGIN';
export const HANDLE_LOGIN_REDIRECT = 'HANDLE_LOGIN_REDIRECT';
export const HANDLED_LOGIN_REDIRECT = 'HANDLED_LOGIN_REDIRECT';

// communities actions
export const REQUEST_COMMUNITIES = 'REQUEST_COMMUNITIES';
export const RECEIVED_COMMUNITIES = 'RECEIVED_COMMUNITIES';
export const FAILED_COMMUNITIES = 'FAILED_COMMUNITIES';

// community actions
export const REQUEST_COMMUNITY = 'REQUEST_COMMUNITY';
export const RECEIVED_COMMUNITY = 'RECEIVED_COMMUNITY';
export const FAILED_COMMUNITY = 'FAILED_COMMUNITY';

// community comment actions
export const REQUEST_COMMUNITY_COMMENTS = 'REQUEST_COMMUNITY_COMMENTS';
export const RECEIVED_COMMUNITY_COMMENTS = 'RECEIVED_COMMUNITY_COMMENTS';
export const FAILED_COMMUNITY_COMMENTS = 'FAILED_COMMUNITY_COMMENTS';

// comment post
export const POST_COMMUNITY_COMMENT = 'POST_COMMUNITY_COMMENT';
export const SUCCESSFULLY_POSTED_COMMUNITY_COMMENT = 'SUCCESSFULLY_POSTED_COMMUNITY_COMMENT';
export const FAILED_POST_COMMUNITY_COMMENT = 'FAILED_POST_COMMUNITY_COMMENT';

// pledge
export const POST_PLEDGE = 'POST_PLEDGE';
export const SUCCESSFULLY_POSTED_PLEDGE = 'SUCCESSFULLY_POSTED_PLEDGE';
export const FAILED_POST_PLEDGE = 'FAILED_POST_PLEDGE';

// user actions
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVED_LOGIN = 'RECEIVED_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const DID_LOGOUT = 'DID_LOGOUT';

export const REQUEST_TEMPORARY_PASSWORD = 'REQUEST_TEMPORARY_PASSWORD';
export const TEMPORARY_PASSWORD_REQUEST_SUCCESS = 'TEMPORARY_PASSWORD_REQUEST_SUCCESS';
export const TEMPORARY_PASSWORD_REQUEST_FAIL = 'TEMPORARY_PASSWORD_REQUEST_FAIL';

export const REQUEST_SIGNUP = 'REQUEST_SIGNUP';
export const RECEIVED_SIGNUP = 'RECEIVED_SIGNUP';
export const FAILED_SIGNUP = 'FAILED_SIGNUP';

// community modals
export const SHOW_LEARN_HOW_CAMPAIGNS_WORK = 'SHOW_LEARN_HOW_CAMPAIGNS_WORK';
export const HIDE_LEARN_HOW_CAMPAIGNS_WORK = 'HIDE_LEARN_HOW_CAMPAIGNS_WORK';

export const SHOW_PLEDGE = 'SHOW_PLEDGE';
export const HIDE_PLEDGE = 'HIDE_PLEDGE';

