import React, { Component } from 'react';
import { propTypes } from '../lib';

import styles from '../styles/modal.scss';

@propTypes({
  title: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired
})
export default class Modal extends Component {
  render() {
    return (
      <div className={styles.modalBackdrop}>
        <div className={styles.modal}>
          <a className={styles.modalCloseButton}
             role="button"
             tabIndex="0"
             onClick={this.onClose} >
            X
          </a>
          <div className={styles.title}>{this.props.title}</div>
          {this.props.children}
        </div>
      </div>
    );
  }

  onClose = () => {
    this.props.onClose();
  }
}
