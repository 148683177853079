/* eslint react/self-closing-comp:0 */

import React from 'react';
import { Route, IndexRoute, Redirect } from 'react-router';

import { NamedRoutes } from './lib';
import { RequireAuth } from './components';
import { LoginPage, ForgotPasswordPage, CommunitiesPage, CommunityPage, SignupPage } from './pages';

export default (
  <div>
    <Redirect from="/"
              to={NamedRoutes.COMMUNITIES} />
    <Route path={NamedRoutes.LOGIN}
           component={LoginPage} />
    <Route path={NamedRoutes.SIGNUP}
           component={SignupPage} />
    <Route path={NamedRoutes.FORGOT_PASSWORD}
           component={ForgotPasswordPage} />
    <Route path={NamedRoutes.COMMUNITIES}
           component={RequireAuth}>
      <IndexRoute component={CommunitiesPage} />
      <Route path={NamedRoutes.COMMUNITY}
             component={CommunityPage} />
    </Route>
  </div>
);
