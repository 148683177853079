import { Map } from 'immutable';
import { logger } from '../lib';
import { ActionTypes } from '../constants';

const allModalsHiddenState = Map({
  showLearnHowCampaignsWork: false,
  showPledge: false
});

const initialState = Map({
  communityLoading: false,
  commentsLoading: false,
  commentPostInProgress: false,
  communityError: null,
  commentsError: null,
  commentPostError: null,
  pledgePostInProgress: false,
  pledgePostError: null,
  community: null
}).merge(allModalsHiddenState);

const handleReceivedCommunityComments = forCurrentCommunity((state, payload) => (
  state.merge({
    commentsLoading: false,
    commentsError: null
  }).mergeIn([ 'community', 'comments' ], payload.comments)
));

const handlePostCommunityComment = forCurrentCommunity((state, payload) => (
  state.updateIn([ 'community', 'comments' ], (comments) => (
    comments.unshift(payload.comment)
  )).merge({
    commentPostInProgress: true,
    commentPostError: null
  })
));

const handleSuccessfullyPostedCommunityComment = forCurrentCommunity((state, payload) => (
  state.updateIn([ 'community', 'comments' ], (comments) => {
    const commentIdx = comments.findIndex((c) => c.get('id') === payload.localCommentId);
    return comments.mergeIn([ commentIdx ], { id: payload.remoteCommentId });
  }).merge({
    commentPostInProgress: false,
    commentPostError: null
  })
));

const handleFailedToPostCommunityComment = forCurrentCommunity((state, payload) => (
  state.updateIn([ 'community', 'comments' ], (comments) => (
    comments.filter((comment) => comment.get('id') !== payload.localCommentId)
  )).merge({
    commentPostInProgress: false,
    commentPostError: payload.error
  })
));

const handlePostPledge = forCurrentCommunity((state, ignoredPayload) => (
  state.merge({
    pledgePostInProgress: true,
    pledgePostError: null
  })
));

const handleSuccessfullyPostedPledge = forCurrentCommunity((state, payload) => (
  state.merge({
    pledgePostInProgress: false,
    pledgePostError: null
  }).updateIn([ 'community', 'campaign', 'currentUserPledge' ], (currentUserPledge) => (
    currentUserPledge.merge({
      amount: (currentUserPledge.get('amount') || 0) + payload.amount,
      currencyCode: payload.currencyCode // TODO: handle payload.currencyCode !== currentUserPledge.get('currencyCode')
    })
  ))
));

const handleFailedToPostPledge = forCurrentCommunity((state, payload) => (
  state.merge({
    pledgePostInProgress: false,
    pledgePostError: payload.error
  })
));

export default function community(state = initialState, action = null) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.REQUEST_COMMUNITY:
      return state.merge({
        communityLoading: true,
        communityError: null,
        community: null
      });
    case ActionTypes.FAILED_COMMUNITY:
      logger.error('Failed to get community', payload.error);
      return state.merge({
        communityLoading: false,
        communityError: payload.error,
        community: null
      });
    case ActionTypes.RECEIVED_COMMUNITY:
      return state.merge({
        communityLoading: false,
        communityError: null,
        community: payload.community
      });
    case ActionTypes.REQUEST_COMMUNITY_COMMENTS:
      return state.merge({
        commentsLoading: true,
        commentsError: null
      });
    case ActionTypes.RECEIVED_COMMUNITY_COMMENTS:
      return handleReceivedCommunityComments(state, action);
    case ActionTypes.FAILED_COMMUNITY_COMMENTS:
      logger.error('Failed to get community comments', payload.error);
      return state.merge({
        commentsLoading: false,
        commentsError: payload.error
      });
    case ActionTypes.POST_COMMUNITY_COMMENT:
      return handlePostCommunityComment(state, action);
    case ActionTypes.SUCCESSFULLY_POSTED_COMMUNITY_COMMENT:
      return handleSuccessfullyPostedCommunityComment(state, action);
    case ActionTypes.FAILED_POST_COMMUNITY_COMMENT:
      return handleFailedToPostCommunityComment(state, action);
    case ActionTypes.SHOW_LEARN_HOW_CAMPAIGNS_WORK:
      return state.merge(allModalsHiddenState).merge({
        showLearnHowCampaignsWork: true
      });
    case ActionTypes.HIDE_LEARN_HOW_CAMPAIGNS_WORK:
      return state.merge(allModalsHiddenState);
    case ActionTypes.SHOW_PLEDGE:
      return state.merge(allModalsHiddenState).merge({
        showPledge: true
      });
    case ActionTypes.HIDE_PLEDGE:
      return state.merge(allModalsHiddenState);
    case ActionTypes.POST_PLEDGE:
      return handlePostPledge(state, action);
    case ActionTypes.SUCCESSFULLY_POSTED_PLEDGE:
      return handleSuccessfullyPostedPledge(state, action);
    case ActionTypes.FAILED_POST_PLEDGE:
      return handleFailedToPostPledge(state, action);
    default:
      return state;
  }
}

function forCurrentCommunity(fn) {
  return (state, action) => {
    const { payload } = action;
    const { communityId } = payload;
    return isCurrentCommunity(state, communityId) ? fn(state, payload) : state;
  };
}

function isCurrentCommunity(state, id) {
  return id === state.getIn([ 'community', 'quitter', 'id' ]);
}
