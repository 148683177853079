import React, { Component } from 'react';
import { propTypes } from '../lib';

import styles from '../styles/loading.scss';

@propTypes({
  className: propTypes.string
})
export default class Loading extends Component {
  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <div className={styles.loading} />
        {this.props.children}
      </div>
    );
  }
}
