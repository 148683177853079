import React, { Component } from 'react';
import { CampaignTypes, CampaignRewardTypes } from 'constants';
import { NameUtils, propTypes } from '../../lib';
import BasicInfo from './basic-info';
import ProfilePic from '../profile-pic';

import styles from '../../styles/campaign-header.scss';

@propTypes({
  community: propTypes.community.isRequired
})
export default class Header extends Component {
  render() {
    const { community } = this.props;
    const quitter = community.get('quitter');
    const campaign = community.get('campaign');

    return (
      <div className={styles.campaignHeader}>
        <ProfilePic className={styles.glowingProfilePic}
                    url={quitter.get('profilePicUrl')}
                    size="large" />
        <div>
          <span className={styles.quitterName}>{NameUtils.fullOrEmail(quitter)}</span>&nbsp;
          <span className={styles.raisingCampaign}>
            {!campaign || campaign.get('rewardType') === CampaignRewardTypes.KUDOS
             ? 'needs your help'
             : 'is raising a campaign'
            }
          </span>&nbsp;
          <span className={styles.campaignType}>{this.campaignType(campaign)}!</span>
        </div>
        {campaign
         ? (
          <BasicInfo community={community}
                     className={styles.campaignInfo} />
         ) : null
        }
      </div>
    );
  }

  campaignType(campaign) {
    const type = campaign ? campaign.get('type') : CampaignTypes.QUIT;

    const campaignTypeTextByCampaignType = {
      [CampaignTypes.QUIT]: 'to quit',
      [CampaignTypes.STAY_QUIT]: 'to stay quit'
    };

    return campaignTypeTextByCampaignType[type];
  }
}
