exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/z-indices.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".modal__modalBackdrop___3nrPi {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(255, 255, 255, 0.75);\n  z-index: " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/z-indices.scss").locals["modal-backdrop"] + "; }\n\n.modal__modal___1zPG1 {\n  position: relative;\n  z-index: " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/z-indices.scss").locals["modal"] + ";\n  padding: 40px 60px;\n  width: 600px;\n  margin: auto;\n  margin-top: 50px;\n  max-height: 80%;\n  overflow-x: hidden;\n  overflow-y: auto; }\n\n.modal__modalCloseButton___WfYJo {\n  position: absolute;\n  top: 15px;\n  right: 15px;\n  font-size: 22px;\n  line-height: 22px;\n  color: #DDDDDD;\n  cursor: pointer; }\n  .modal__modalCloseButton___WfYJo:hover {\n    text-decoration: none; }\n\n.modal__title___2WccQ {\n  text-align: center;\n  margin-bottom: 28px; }\n", ""]);

// Exports
exports.locals = {
	"modal-backdrop-z": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/z-indices.scss").locals["modal-backdrop"] + "",
	"modal-z": "" + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/z-indices.scss").locals["modal"] + "",
	"modalBackdrop": "modal__modalBackdrop___3nrPi",
	"modal": "modal__modal___1zPG1 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["popup"] + "",
	"modalCloseButton": "modal__modalCloseButton___WfYJo",
	"title": "modal__title___2WccQ " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["popupTitle"] + ""
};