exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");

// Module
exports.push([module.id, ".forgot-password__container___2mitJ {\n  width: 100%;\n  padding-top: 80px; }\n\n.forgot-password__body___xgQyW { }\n\n.forgot-password__innerContainer___2wr8z {\n  margin: 30px auto;\n  text-align: center;\n  max-width: 600px;\n  width: 100%;\n  padding: 40px 60px; }\n\n.forgot-password__title___3d6hY {\n  margin-bottom: 20px; }\n\n.forgot-password__subtitle___1qrD1 {\n  margin-top: 20px;\n  margin-bottom: 20px; }\n\n.forgot-password__input___2J5eu {\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 15px;\n  text-align: center; }\n\n.forgot-password__description___OD2Sz {\n  margin-top: 10px; }\n\n.forgot-password__button___1IOq- {\n  width: 100%;\n  margin-top: 20px; }\n\n.forgot-password__loading___1BwSI { }\n\n.forgot-password__loginLinkContainer___1a9uw {\n  width: 100%;\n  text-align: center; }\n\n.forgot-password__loginLink___2w-Mw { }\n\n.forgot-password__forgotPasswordSuccessInstruction___3hl4b {\n  margin-top: 10px; }\n", ""]);

// Exports
exports.locals = {
	"container": "forgot-password__container___2mitJ",
	"body": "forgot-password__body___xgQyW " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer"] + "",
	"innerContainer": "forgot-password__innerContainer___2wr8z " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["popup"] + "",
	"title": "forgot-password__title___3d6hY " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["popupTitle"] + "",
	"subtitle": "forgot-password__subtitle___1qrD1 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["body"] + "",
	"input": "forgot-password__input___2J5eu " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["inputBox"] + "",
	"description": "forgot-password__description___OD2Sz " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["body"] + "",
	"button": "forgot-password__button___1IOq- " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton1"] + "",
	"loading": "forgot-password__loading___1BwSI " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["loading"] + "",
	"loginLinkContainer": "forgot-password__loginLinkContainer___1a9uw",
	"loginLink": "forgot-password__loginLink___2w-Mw " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["textButton2"] + "",
	"forgotPasswordSuccessInstruction": "forgot-password__forgotPasswordSuccessInstruction___3hl4b " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["body"] + ""
};