exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".campaign-how-it-works__body___TAoUg { }\n\n.campaign-how-it-works__emphasis___1QN4_ { }\n", ""]);

// Exports
exports.locals = {
	"body": "campaign-how-it-works__body___TAoUg " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["body"] + "",
	"emphasis": "campaign-how-it-works__emphasis___1QN4_ " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["bodyEmphasis"] + ""
};