exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");

// Module
exports.push([module.id, ".signup__container___3N77Y {\n  width: 100%;\n  padding-top: 80px; }\n\n.signup__body___23iJS { }\n\n.signup__signup___1whCI {\n  margin: 30px auto;\n  text-align: center;\n  max-width: 600px;\n  width: 100%;\n  padding: 40px 60px; }\n\n.signup__title___bjhEh {\n  margin-bottom: 20px; }\n\n.signup__input___2paJV {\n  display: block;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 15px;\n  text-align: center; }\n\n.signup__signupButton___2zvmS {\n  width: 100%;\n  margin-top: 20px; }\n\n.signup__loading___bGKue { }\n\n.signup__loginLink___1wDbE { }\n\n.signup__loginContainer___1y51u {\n  width: 100%;\n  text-align: center; }\n", ""]);

// Exports
exports.locals = {
	"container": "signup__container___3N77Y",
	"body": "signup__body___23iJS " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer"] + "",
	"signup": "signup__signup___1whCI " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["popup"] + "",
	"title": "signup__title___bjhEh " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["popupTitle"] + "",
	"input": "signup__input___2paJV " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["inputBox"] + "",
	"signupButton": "signup__signupButton___2zvmS " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton1"] + "",
	"loading": "signup__loading___bGKue " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["loading"] + "",
	"loginLink": "signup__loginLink___1wDbE " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["textButton2"] + "",
	"loginContainer": "signup__loginContainer___1y51u"
};