import { Map, List } from 'immutable';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import config from './config';

const API_ROOT = config.apiRoot;

export class ApiError extends Error {
  constructor(message, status) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.status = status;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor.name);
    } else {
      this.stack = Error.stack || '';
    }
  }
}

// system-level, un-retriable error
export class SystemError extends ApiError { }

// user error, retriable with different params
export class UserError extends ApiError {
  constructor(message, status, data) {
    super(message, status);
    this.data = data;
  }
}

export function login(email, password) {
  return post(null, url('sessions'), null, {
    user: {
      email_address: email,
      password
    },
    session_type: 'supporter'
  }).then((res) => (
    Map({
      token: res.token,
      user: userFromServer(res.user)
    })
  ));
}

export function signup(email, password, passwordConfirmation) {
  return post(null, url('users'), null, {
    user: {
      email_address: email,
      password,
      password_confirmation: passwordConfirmation,
      user_type: 'supporter'
    }
  }).then((res) => (
    Map({
      token: res.token,
      user: userFromServer(res.user)
    })
  ));
}

export function requestTemporaryPassword(email) {
  return post(null, url('password_resets'), null, {
    password_reset: {
      email_address: email
    }
  });
}

export function communities(token) {
  return get('communities', url('communities'), token).then((res) => (
    dedupeCommunities(List(res.map(communityFromServer)))
  ));
}

export function community(token, id) {
  return get('community', url(`communities/${id}`), token).then(communityFromServer);
}

export function postComment(token, id, message) {
  return post('comment', url(`communities/${id}/comments`), token, {
    comment: {
      content: message
    }
  }).then(Map);
}

export function communityComments(token, id) {
  return get('comments', url(`communities/${id}/comments`), token).then((comments) => (
    List(comments.map(commentFromServer))
  ));
}

export function pledge(token, communityId, amountCents, currencyCode, stripeToken) {
  return post('pledge', url(`communities/${communityId}/pledges`), token, {
    pledge: {
      amount_cents: amountCents,
      amount_currency_code: currencyCode,
      stripe_token: stripeToken
    }
  }).then(Map);
}


function dedupeCommunities(dupedCommunities) {
  // TODO: needed since the server currently may return multiple communities for a given user. should be fixed api-side
  return dupedCommunities.groupBy((c) => c.getIn([ 'quitter', 'id' ]))
                         .valueSeq()
                         .map((cs) => cs.first())
                         .toList();
}

function communityFromServer(serverCommunity) {
  return Map({
    quitter: userFromServer(serverCommunity.quitter),
    comments: serverCommunity.comments ? List(serverCommunity.comments.map(commentFromServer)) : List(),
    supporters: List(serverCommunity.supporters.map(userFromServer)),
    isPrivate: serverCommunity.invite_only,
    campaign: campaignFromServer(serverCommunity.campaign)
  });
}

function campaignFromServer(serverCampaign) {
  if ( !serverCampaign ) {
    return null;
  }

  return Map({
    type: 'quit', // TODO: we only have quit campaigns for now
    rewardType: serverCampaign.reward_type,
    rewardDescription: serverCampaign.reward_description,
    isVerifiedByHonesty: true, // TODO: we only have honesty-verified campaigns for now
    selfPledge: moneyFromServer(serverCampaign, 'self_pledged'),
    currentUserPledge: moneyFromServer(serverCampaign, 'current_user_pledged'),
    startDate: dateFromServer(serverCampaign.start_date),
    durationDays: serverCampaign.duration_days,
    totalPledged: moneyFromServer(serverCampaign, 'total_pledged'),
    charity: charityFromServer(serverCampaign.charity)
  });
}

function charityFromServer(serverCharity) {
  return serverCharity ? Map({
    id: serverCharity.id,
    name: serverCharity.name,
    imageUrl: serverCharity.logo_url
  }) : null;
}

function commentFromServer(serverComment) {
  return Map({
    id: serverComment.id,
    user: userFromServer(serverComment.author),
    date: dateFromServer(serverComment.created_at),
    message: serverComment.content
  });
}

function userFromServer(serverUser) {
  if ( !serverUser ) {
    return null;
  }

  return Map({
    id: serverUser.id,
    profilePicUrl: serverUser.profile_photo && serverUser.profile_photo.url,
    firstName: serverUser.first_name,
    lastName: serverUser.last_name,
    email: serverUser.email_address
  });
}

function moneyFromServer(serverMoneyContainer, moneyName) {
  return Map({
    amount: serverMoneyContainer[moneyName + '_amount'],
    currencyCode: serverMoneyContainer[moneyName + '_currency_code']
  });
}

function dateFromServer(date) {
  return moment(date);
}

function post(responseKey, postUrl, token, body) {
  return fetch(postUrl, {
    method: 'post',
    headers: getHeaders(token),
    body: JSON.stringify(body)
  }).then(handleResponse.bind(null, responseKey));
}

function get(responseKey, getUrl, token, params) {
  return fetch(getUrl + queryStringParams(params), {
    method: 'get',
    headers: getHeaders(token)
  }).then(handleResponse.bind(null, responseKey));
}

function getHeaders(token, methodOverride) {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  if (methodOverride) {
    headers.set('x-http-method-override', methodOverride);
  }

  if ( token ) {
    headers.set('Authorization', `Token ${token}`);
  }

  return headers;
}

function url(path) {
  return `${API_ROOT}/${path}`;
}

function handleResponse(responseKey, response) {
  if ( response.ok ) {
    return response.text().then((text) => {
      const data = text ? JSON.parse(text) : null;
      return (responseKey && data) ? data[responseKey] : data;
    });
  }

  const status = response.status;
  if ( status >= 400 && status < 500 ) {
    return response.json().then(throwUserError, throwUserError);
  }

  throw new SystemError(response.statusText, status);

  function throwUserError(data) {
    throw new UserError(response.statusText, status, data);
  }
}

function queryStringParams(params) {
  if (!params) {
    return '';
  }

  const immutableMapParams = Map(params);
  return immutableMapParams.reduce( (rVal, value, key) => {
    const prefix = rVal.length === 0 ? '?' : '&';
    return prefix + rVal + key + '=' + encodeURIComponent(params[key]);
  }, '');
}
