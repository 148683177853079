import LoginPage from './login';
import CommunityPage from './community';
import CommunitiesPage from './communities';
import SignupPage from './signup';
import ForgotPasswordPage from './forgot-password';

export {
  LoginPage,
  CommunityPage,
  CommunitiesPage,
  SignupPage,
  ForgotPasswordPage
};
