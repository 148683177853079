exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".comment-input__body___2zRV6 {\n  padding: 10px 20px; }\n\n.comment-input__profilePic___2duLi {\n  display: inline-block; }\n\n.comment-input__name___QCGzV { }\n\n.comment-input__comment___XLIs4 {\n  height: 80px;\n  width: 100%;\n  margin-top: 10px;\n  padding: 8px;\n  resize: vertical; }\n\n.comment-input__send___2JuTi {\n  float: right;\n  margin-top: 10px;\n  width: 200px;\n  height: 60px; }\n\n.comment-input__loading___1ha8I { }\n", ""]);

// Exports
exports.locals = {
	"body": "comment-input__body___2zRV6 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["primaryContainer,"] + " " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["box"] + " " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["clearfix"] + "",
	"profilePic": "comment-input__profilePic___2duLi",
	"name": "comment-input__name___QCGzV " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["name"] + "",
	"comment": "comment-input__comment___XLIs4",
	"send": "comment-input__send___2JuTi " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton1"] + "",
	"loading": "comment-input__loading___1ha8I " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./utils.scss").locals["loading"] + ""
};