import * as CommunitiesActions from './communities-actions';
import * as CommunityActions from './community-actions';
import * as UserActions from './user-actions';
import * as NavActions from './nav-actions';

export {
  CommunitiesActions,
  CommunityActions,
  UserActions,
  NavActions
};
