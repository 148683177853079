import React, { Component } from 'react';
import { List } from 'immutable';

export function propChangeHandler(handler) {
  return (WrappedComponent) => (
    class PropChangeHandler extends Component {
      componentWillMount() {
        handler(this.props);
      }

      componentWillReceiveProps(nextProps) {
        handler(nextProps);
      }

      render() {
        return React.createElement(WrappedComponent, this.props);
      }
    }
  );
}

/**
 * TODO: This mixer isn't respecting existing properties at the moment, would be nice to keep adding to the stack of
 * functions and have them still execute as expected (although it's making some spaghetti).
 */
export function classPrototypeMixin(mixer) {
  const typeTag = Symbol('isa');

  function _mixin(clazz) {
    const mixerKeys = List(Reflect.ownKeys(mixer));
    mixerKeys.forEach((property) => {
      Object.defineProperty(clazz.prototype, property, {
        value: mixer[property],
        writable: true
      });
    });
    Object.defineProperty(clazz.prototype, typeTag, { value: true });
    return clazz;
  }
  Object.defineProperty(_mixin, Symbol.hasInstance, {
    value: (i) => !!i[typeTag]
  });

  return _mixin;
}
