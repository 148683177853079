import { Map } from 'immutable';

const currencySymbolByCode = {
  'USD': '$'
};

export function formatWhole(money) {
  const amount = money.get('amount');
  const currency = money.get('currencyCode');

  // we only handle USD for now

  return `${currencySymbolByCode[currency]}${Math.round(amount / 100)}`;
}

export function moneyFromWholeUnitsInDefaultCurrency(amountInWholeUnits) {
  return Map({
    amount: amountInWholeUnits * 100,
    currencyCode: userCurrencyCode()
  });
}

export function userCurrencyCode() {
  return 'USD';
}
