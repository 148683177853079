import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { propTypes, MoneyUtils } from '../../lib';
import { CampaignRewardTypes } from 'constants';

import styles from '../../styles/campaign-basic-info.scss';

@propTypes({
  community: propTypes.community.isRequired,
  className: propTypes.string,
  valueClassName: propTypes.string,
  labelClassName: propTypes.string
})
export default class BasicInfo extends Component {
  static defaultProps = {
    valueClassName: styles.value,
    labelClassName: styles.label
  };

  render() {
    const { community, labelClassName, valueClassName } = Object.assign({}, BasicInfo.defaultProps, this.props);
    const campaign = community.get('campaign');
    const startsInFuture = campaign.get('startDate').isAfter();

    return (
      <div className={classnames(this.props.className, styles.campaignInfo)}>
        <div className={styles.campaignInfoItem}>
          <div className={valueClassName}>{Math.abs(moment().diff(campaign.get('startDate'), 'days'))}</div>
          <div className={labelClassName}>{startsInFuture ? 'days left until start' : 'days into campaign'}</div>
        </div>
        {this.renderTotalPledged(campaign, labelClassName, valueClassName)}
        <div className={styles.campaignInfoItem}>
          <div className={valueClassName}>{community.get('supporters').size}</div>
          <div className={labelClassName}>joined</div>
        </div>
      </div>
    );
  }

  renderTotalPledged(campaign, labelClassName, valueClassName) {
    return campaign.get('rewardType') === CampaignRewardTypes.KUDOS
      ? (
        <div className={styles.campaignInfoItem}>
          <div className={valueClassName}>Kudos</div>
          <div className={labelClassName}>just encouragement</div>
        </div>
      ) : (
        <div className={styles.campaignInfoItem}>
          <div className={valueClassName}>{MoneyUtils.formatWhole(campaign.get('totalPledged'))}</div>
          <div className={labelClassName}>{`raised of ${MoneyUtils.formatWhole(campaign.get('totalPledged'))} target`}</div>
        </div>
      );
  }
}
