import React, { Component } from 'react';
import classnames from 'classnames';
import { propTypes, NameUtils } from '../../lib';
import ProfilePic from '../profile-pic';
import BasicInfo from '../campaign/basic-info';

import styles from '../../styles/community-thumbnail.scss';

@propTypes({
  community: propTypes.community.isRequired,
  onSelected: propTypes.func.isRequired,
  className: propTypes.string
})
export default class Thumbnail extends Component {
  constructor(props) {
    super(props);

    this.onSelected = () => {
      this.props.onSelected();
    };
  }

  render() {
    const { community, className } = this.props;
    const quitter = community.get('quitter');

    return (
      <div className={classnames(className, styles.container)}>
        <ProfilePic size="large"
                    url={quitter.get('profilePicUrl')} />
        <div className={styles.name}>
          {NameUtils.fullOrEmail(quitter)}
        </div>
        <div className={styles.email}>
          {quitter.get('email')}
        </div>
        <hr />
        {community.get('campaign')
         ? (
          <BasicInfo community={community}
                     valueClassName={styles.infoValue}
                     labelClassName={styles.infoLabel} />
         ) : null
        }
        <button className={styles.button}
                onClick={this.onSelected}>
          Open
        </button>
      </div>
    );
  }
}
