import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Nav, ErrorMsg } from '../components';
import { Link } from 'react-router';
import { NamedRoutes } from '../lib';
import { UserActions } from '../actions';

import styles from '../styles/forgot-password.scss';

@connect((state) => ({
  userData: state.userData,
  nav: state.nav
}))
export default class ForgotPasswordPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: ''
    };

    this.onUpdateEmail = this.update.bind(this, 'email');
    this.onRequestForgotPassword = () => {
      this.props.dispatch(UserActions.requestTemporaryPassword(this.state.email));
    };
  }

  render() {
    const { userData } = this.props;
    const loading = userData.get('loading');
    const forgotButtonClasses = {
      [styles.button]: true,
      [styles.loading]: loading
    };

    const error = userData.get('forgotPasswordError');
    const message = userData.get('forgotPasswordMessage');

    return (
      <div className={styles.container}>
        <Nav user={null} />
        <div className={styles.body}>
          <div className={styles.innerContainer}>
            <div className={styles.title}>Forgot Password</div>
            <div className={styles.subtitle}>
              Your temporary password will be sent to
            </div>
            <input type="email"
                   className={styles.input}
                   placeholder="Email"
                   value={this.state.email}
                   onChange={this.onUpdateEmail} />
            <div className={styles.description}>
              We'll send you an email so you can quickly change your password.
            </div>
            <button className={classnames(forgotButtonClasses)}
                    onClick={this.onRequestForgotPassword}>
              Request temporary password
            </button>
            {this.renderMessage(error, message)}
          </div>
          <p className={styles.loginLinkContainer}>
            <Link to={NamedRoutes.LOGIN}
                  className={styles.loginLink}>
              Back to Sign in
            </Link>
          </p>
        </div>
      </div>
    );
  }

  update(key, evt) {
    this.setState({ [key]: evt.target.value });
  }

  renderMessage = (error, message) => {
    if (error) {
      return (
        <ErrorMsg error={error} />
      );
    }

    if (message) {
      return (
        <div className={styles.forgotPasswordSuccessInstruction}>
          {message}
        </div>
      );
    }

    return null;
  }
}
