import React, { Component } from 'react';
import classnames from 'classnames';
import { propTypes, Api } from '../lib';

import styles from '../styles/error-msg.scss';

@propTypes({
  error: propTypes.instanceOf(Error),
  msgByStatus: propTypes.object,
  className: propTypes.string
})
export default class ErrorMsg extends Component {
  render() {
    const { error, className } = this.props;

    if ( !error ) {
      return null;
    }

    return (
      <div className={classnames(className, styles.error)}>
        {this.errorMsg()}
      </div>
    );
  }

  errorMsg() {
    const { error, msgByStatus, children } = this.props;

    if ( React.Children.count(children) ) {
      // we were explicitly passed a msg.  show that
      return children;
    }

    if ( msgByStatus && error.status && msgByStatus[error.status] ) {
      const msg = msgByStatus[error.status];
      return typeof msg === 'function' ? msg(error) : msg;
    }

    if ( error.userFacingMsg ) {
      // the error defines a message suitable to show the user
      return error.userFacingMsg;
    }

    if ( error instanceof Api.SystemError ) {
      return 'A system error occured but we\'re on it!';
    }

    return 'An error occured but we\'re working to resolve it right now.';
  }
}
