import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import classnames from 'classnames';
import { Loading, Nav, Campaign, Community, Modal, ErrorMsg } from '../components';
import { CommunityActions, UserActions } from '../actions';
import { CampaignRewardTypes } from 'constants';
import { ReactAnnotations, NameUtils, NamedRoutes } from '../lib';

import '../styles/3ps/bootstrap.min.css';
import styles from '../styles/community.scss';

const { propChangeHandler } = ReactAnnotations;

function isMissingData(props) {
  const { communityData, routeParams } = props;
  const community = communityData.get('community');
  const error = communityData.get('communityError');

  return !error && (!community || (+routeParams.quitterId) !== community.getIn([ 'quitter', 'id' ]));
}

function handleRouteChange(props) {
  if ( isMissingData(props) ) {
    const { userData, routeParams } = props;
    props.dispatch(CommunityActions.get(userData.get('sessionToken'), routeParams.quitterId));
  }
}

@connect((state) => {
  return {
    userData: state.userData,
    communityData: state.communityData
  };
})
@propChangeHandler(handleRouteChange)
export default class CommunityPage extends Component {
  constructor(props) {
    super(props);

    this.onPostComment = (message) => {
      const { dispatch, userData, communityData } = this.props;
      dispatch(CommunityActions.postComment(userData.get('sessionToken'), userData.get('user'), communityData.getIn([ 'community', 'quitter', 'id' ]), message));
    };

    this.onShowLearnHowCampaignsWork = () => {
      this.props.dispatch(CommunityActions.showLearnHowCampaignsWork());
    };

    this.onHideLearnHowCampaignsWork = () => {
      this.props.dispatch(CommunityActions.hideLearnHowCampaignsWork());
    };

    this.onShowPledge = () => {
      this.props.dispatch(CommunityActions.showPledge());
    };

    this.onHidePledge = () => {
      this.props.dispatch(CommunityActions.hidePledge());
    };

    this.onPledge = (pledge) => {
      const { dispatch, userData, communityData } = this.props;
      const pledgeInfo = {
        amountCents: pledge.amountCents,
        currencyCode: pledge.currencyCode,
        cardNumber: pledge.cardNumber,
        cvc: pledge.cvc,
        expirationMonth: pledge.expirationMonth,
        expirationYear: pledge.expirationYear
      };
      dispatch(CommunityActions.pledge(userData.get('sessionToken'), communityData.getIn([ 'community', 'quitter', 'id' ]), pledgeInfo));
    };
  }

  render() {
    const { userData } = this.props;
    const user = userData.get('user');
    return (
      <div className={styles.container}>
        <Nav user={user}
             onLogout={this.onLogout.bind(null, this.props)} />
        {this.renderInner()}
      </div>
    );
  }

  renderInner() {
    const { userData, communityData } = this.props;
    const user = userData.get('user');
    const error = communityData.get('communityError');

    if ( error ) {
      return (
          <ErrorMsg error={error}>
            <div className={styles.error}>
              We ran into a problem loading your community.&nbsp;
              <br />
              <Link to={NamedRoutes.COMMUNITIES}>
                Return to communities.
              </Link>
            </div>
          </ErrorMsg>
      );
    }

    if ( isMissingData(this.props) || communityData.get('communityLoading') ) {
      return (
          <Loading className={styles.loading}>
            Loading...
          </Loading>
      );
    }

    const community = communityData.get('community');
    const quitter = community.get('quitter');
    const campaign = community.get('campaign');
    const involvesMoney = campaign && CampaignRewardTypes.involveMoney.has(campaign.get('rewardType'));

    return (
      <div>
        <Campaign.NavSubheader quitter={quitter} />
        <Campaign.Header community={community} />
        <div className={styles.body}>
          {involvesMoney
           ? (
            <div className={styles.secondaryContainer}>
              <Campaign.UseOfMoney className={styles.useOfMoney}
                                   campaign={campaign} />
              <Campaign.CurrentUserPledge campaign={campaign}
                                          onPledge={this.onPledge}
                                          onShowPledge={this.onShowPledge}
                                          onLearnHowCampaignsWork={this.onShowLearnHowCampaignsWork} />
            </div>
           ) : null
          }
          <div className={classnames({ [styles.primaryContainer]: involvesMoney })}>
            {this.renderCommentArea(user, communityData)}
          </div>
        </div>
        {this.renderModals()}
      </div>
    );
  }

  renderModals() {
    const { communityData } = this.props;
    const community = communityData.get('community');

    if ( communityData.get('showLearnHowCampaignsWork') ) {
      return (
        <Modal title="Learn how it works"
               onClose={this.onHideLearnHowCampaignsWork}>
          <Campaign.HowItWorks community={community} />
        </Modal>
      );
    } else if ( communityData.get('showPledge') ) {
      return (
        <Modal title={`Pledge to ${NameUtils.firstOrEmail(community.get('quitter'))}'s campaign`}
               onClose={this.onHidePledge}>
          <Campaign.Pledge onPledge={this.onPledge}
                           quitter={community.get('quitter')}
                           onPledgeDone={this.onHidePledge}
                           pledgePostError={communityData.get('pledgePostError')}
                           pledgePostInProgress={communityData.get('pledgePostInProgress')} />
        </Modal>
      );
    }

    return null;
  }

  renderCommentArea(user, communityData) {
    const community = communityData.get('community');
    const postInProgress = communityData.get('commentPostInProgress');
    const postError = communityData.get('commentPostError');

    return (
      <div className={styles.fullContainer}>
        <Community.CommentInput user={user}
                                community={community}
                                onPostComment={this.onPostComment}
                                postError={postError}
                                postInProgress={postInProgress} />
        <Community.CommentList className={styles.commentList}
                               community={community} />
      </div>
    );
  }

  onLogout(props) {
    props.dispatch(UserActions.logout());
  }
}
