import React, { Component } from 'react';
import classnames from 'classnames';
import { MoneyUtils, propTypes } from '../../lib';

import styles from '../../styles/campaign-current-user-pledge.scss';

@propTypes({
  campaign: propTypes.campaign.isRequired,
  onLearnHowCampaignsWork: propTypes.func.isRequired,
  onShowPledge: propTypes.func.isRequired
})
export default class CurrentUserPledge extends Component {
  constructor(props) {
    super(props);

    this.onLearnHowCampaignsWork = () => { this.props.onLearnHowCampaignsWork(); };
    this.onShowPledge = () => { this.props.onShowPledge(); };
  }

  render() {
    const { campaign } = this.props;
    const pledge = campaign.get('currentUserPledge');
    const alreadyPledged = pledge && pledge.get('amount');

    return (
      <div className={classnames(styles.body, this.props.className)}>
        <div className={styles.title}>Your pledge</div>
        <div className={styles.money}>
          {alreadyPledged
           ? MoneyUtils.formatWhole(pledge)
           : 'None (yet)'
          }
        </div>
        <button onClick={this.onShowPledge}
                className={styles.pledgeButton}>
          {alreadyPledged ? 'Pledge more' : 'Pledge'}
        </button>
        <a className={styles.learnHowLink}
           onClick={this.onLearnHowCampaignsWork}
           role="link"
           tabIndex="0">
          Learn how it works
        </a>
      </div>
    );
  }
}
