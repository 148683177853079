exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profile-pic__roundImg___3DDQv {\n  border-radius: 50%; }\n\n.profile-pic__small___od-uW {\n  width: 35px;\n  height: 35px; }\n\n.profile-pic__medium___9kwM6 {\n  width: 50px;\n  height: 50px; }\n\n.profile-pic__large___2f__b {\n  width: 90px;\n  height: 90px;\n  background: #f7f7f7; }\n", ""]);

// Exports
exports.locals = {
	"roundImg": "profile-pic__roundImg___3DDQv",
	"small": "profile-pic__small___od-uW profile-pic__roundImg___3DDQv",
	"medium": "profile-pic__medium___9kwM6 profile-pic__roundImg___3DDQv",
	"large": "profile-pic__large___2f__b profile-pic__roundImg___3DDQv"
};