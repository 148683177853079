exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss"), "");

// Module
exports.push([module.id, ".campaign-current-user-pledge__body___2hHIz {\n  text-align: center;\n  padding: 15px 20px; }\n\n.campaign-current-user-pledge__title___I8WXB {\n  margin-bottom: 10px; }\n\n.campaign-current-user-pledge__money___3YRG1 {\n  margin-bottom: 15px; }\n\n.campaign-current-user-pledge__pledgeButton___3jrMu {\n  display: block;\n  width: 100%;\n  margin-bottom: 20px; }\n\n.campaign-current-user-pledge__learnHowLink___1kOfK {\n  cursor: pointer; }\n", ""]);

// Exports
exports.locals = {
	"body": "campaign-current-user-pledge__body___2hHIz " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxPledge"] + "",
	"title": "campaign-current-user-pledge__title___I8WXB " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["title"] + "",
	"money": "campaign-current-user-pledge__money___3YRG1 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["value"] + "",
	"pledgeButton": "campaign-current-user-pledge__pledgeButton___3jrMu " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/boxes.scss").locals["boxButton2"] + "",
	"learnHowLink": "campaign-current-user-pledge__learnHowLink___1kOfK " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-2-1!../../node_modules/sass-loader/lib/loader.js??ref--5-oneOf-2-2!./styleguide/fonts.scss").locals["textButton2"] + ""
};