import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CommunitiesActions, CommunityActions, UserActions } from '../actions';
import { Loading, Nav, Community, ErrorMsg } from '../components';
import { ReactAnnotations } from '../lib';

import '../styles/3ps/bootstrap.min.css';
import styles from '../styles/communities.scss';

const { propChangeHandler } = ReactAnnotations;

function isMissingData(props) {
  const { communitiesData } = props;
  const communities = communitiesData.get('communities');
  const error = communitiesData.get('error');

  return !error && !communities;
}

function handleRouteChange(props) {
  if (isMissingData(props)) {
    const { dispatch, token } = props;
    dispatch(CommunitiesActions.get(token));
  }
}

@connect((state) => {
  const { userData } = state;
  return {
    token: userData.get('sessionToken'),
    user: userData.get('user'),
    communitiesData: state.communitiesData
  };
})
@propChangeHandler(handleRouteChange)
export default class CommunitiesPage extends Component {

  render() {
    const { user } = this.props;

    return (
      <div className={styles.container}>
        <Nav user={user}
             onLogout={this.onLogout.bind(null, this.props)}/>
        {this.renderInner()}
      </div>
    );
  }

  renderInner() {
    const { communitiesData } = this.props;
    const error = communitiesData.get('error');

    if ( error ) {
      return (
          <ErrorMsg error={error}
                    className={styles.error}>
            We ran into a problem loading your communities. Refresh to try again.
          </ErrorMsg>
      );
    }

    if ( isMissingData(this.props) || communitiesData.get('loading') ) {
      return (
          <Loading className={styles.loading}>
            Loading...
          </Loading>
      );
    }

    const communities = communitiesData.get('communities');

    if ( communities.isEmpty() ) {
      return (
          <p className={styles.noCommunities}>
            Looks like you don't have any communities you're supporting. Befriend some Clickotine users and help them quit!
          </p>
      );
    }

    return (
        <div className={styles.body}>
          <div className={styles.title}>Current campaigns</div>
          {communities.map((community) => {
            const quitterId = community.getIn([ 'quitter', 'id' ]);
            return (
              <Community.Thumbnail key={quitterId}
                                   className={styles.thumbnail}
                                   community={community}
                                   onSelected={this.onCommunitySelected.bind(this, community)} />
            );
          })}
        </div>
    );
  }

  onCommunitySelected(community) {
    const { dispatch, token } = this.props;
    dispatch(CommunityActions.showCommunity(token, community));
  }

  onLogout(props) {
    props.dispatch(UserActions.logout());
  }
}
